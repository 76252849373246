<template>
  <div id="employee-new-modal">
    <vs-popup class="popup90" :title="title" :active.sync="localShow">

      <permission-password style="z-index: 9999999999;" :show="popupPermissionPassword" title="ALERTA DE BAIXA RETROATIVA" icon="warning"
        actionButtonText="Prosseguir"
        @action="save(true)"
        @cancel="popupPermissionPassword = false">
      </permission-password>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input class="w-full mt-0" label="Nota Fiscal" v-model="vehicleMaintenance.nota_fiscal" />
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="date" class="w-full mt-0" label="Realização" v-model="vehicleMaintenance.date_of_exchange" data-vv-as="Realização" v-validate.initial="'required'" name="date_of_exchange" />
              <span class="text-danger text-sm" v-show="errors.has('date_of_exchange')">{{ errors.first('date_of_exchange') }}</span>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="date" class="w-full mt-0" label="Validade" v-model="vehicleMaintenance.exchange_expiration" />
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="number" min="0" class="w-full mt-0" label="KM Atual" v-model="vehicleMaintenance.current_mileage"
                data-vv-as="KM Atual" v-validate.initial="'min_value:0'" name="current_mileage" />
              <span class="text-danger text-sm" v-show="errors.has('current_mileage')">Mínimo 0</span>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="number" class="w-full mt-0" label="Retornar com KM" v-model="vehicleMaintenance.next_exchange"
                data-vv-as="Retornar com KM" v-validate.initial="'min_value:0'" name="next_exchange" />
              <span class="text-danger text-sm" v-show="errors.has('next_exchange')">Mínimo 0</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Responsável</label>
              <v-select v-model="vehicleMaintenance.employee_id" :reduce="option => option.value"
                data-vv-as="Responsável" v-validate.initial="'required'" name="employee_id"
                :clearable="true" :options="employees" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('employee_id')">{{ errors.first('employee_id') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Fornecedor</label>
              <v-select v-model="vehicleMaintenance.provider_id" @search="debouncedGetSearchProvider" @option:selected="selectedProvider" :clearable="true" :filterable="false"
                :reduce="option => option.value" :options="providerOptions" data-vv-as="Fornecedor" v-validate.initial="'required'" name="provider_id"
                placeholder="Digite CPF/CNPJ ou nome do fornecedor..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('provider_id')">{{ errors.first('provider_id') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Total</label>
              <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
                  v-model="vehicleMaintenance.value_total"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="InfoIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Serviços Realizados</span>
      </div>
      <hr class="mb-2">

     <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row" v-for="(service, i) in vehicleMaintenance.services" :key="'service_'+i">
            <div class="vx-col md:w-1/6 w-full mt-0">
              <vs-input type="number" min="1" class="w-full mt-0" label="Qtd." v-model="service.quantity"
                data-vv-as="Qtd." v-validate.initial="'required|min_value:1'" :name="'quantity_'+i" />
              <span class="text-danger text-sm" v-show="errors.has('quantity_'+i)">Mínimo 1</span>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <label class="vs-input--label">Total</label>
              <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
                  v-model="service.value"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </div>

            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Serviço</label>{{service.service}}
              <v-select class="vue_select_drop_size_160" v-model="service.service" :reduce="option => option.value"
                data-vv-as="Serviço" v-validate="'required'" :name="'service_'+i"
                :clearable="false" :options="serviceMaintenances" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('service_'+i)">Obrigatório</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
                <vx-tooltip :text="i ? 'Remover Serviço' : 'Adicionar Serviço'" position="top" class="h-5 w-5 mt-5">
                  <feather-icon v-if="i === 0" @click="addServiceField" icon="PlusCircleIcon" svgClasses="w-10 h-10" class="cursor-pointer hover:text-primary" />
                  <feather-icon v-else @click="removeServiceField(i)" icon="MinusCircleIcon" svgClasses="w-10 h-10" class="text-danger cursor-pointer hover:text-primary" />
                </vx-tooltip>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-full w-full">
          <vs-input class="w-full mt-2" label="Descrição dos serviços" v-model="vehicleMaintenance.service_description" />
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="DollarSignIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Pagamento</span>
      </div>
      <hr class="mb-2">

      <div class="vx-row">
        <!-- <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Pagar Para</label>

          <div class="mt-0 flex flex-wrap items-center justify">

          <v-select v-model="debit.student_id" @option:selected="selectedProvider" @search="debouncedGetSearchProvider" :clearable="false" :filterable="false"
            :reduce="option => option.value" :options="providerOptions" data-vv-as="Fornecedor" data-vv-scope="debit" v-validate.initial="'required'" name="student"
            placeholder="Digite CPF/CNPJ ou nome do fornecedor/aluno..." :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-90__">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <feather-icon @click="popupProvider = true" title="Novo Fornecedor" icon="UserPlusIcon" class="ml-auto inline-flex rounded-full" svgClasses="w-8 h-8 cursor-pointer hover:text-primary"></feather-icon>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('debit.student')">Campo obrigatório</span>
        </div> -->
        <!-- <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input class="w-full" label="Valor Total" ref="total" v-model="debit.total" v-currency="currencyConfig" />
        </div> -->
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input type="date" class="w-full" label="1º Vencimento" v-model="debit.expirationDateFirstParcel"
            data-vv-as="1º Vencimento" data-vv-scope="debit" v-validate.initial="'required'" name="expirationDateFirstParcel" />
          <span class="text-danger text-sm" v-show="errors.has('debit.expirationDateFirstParcel')">Este campo é obrigatório.</span>
          <span class="text-danger text-sm" v-if="!isSameOrAfterToday(debit.expirationDateFirstParcel)">A primeira parcela já está vencida.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Periodicidade</label>
          <v-select v-model="debit.frequency" :reduce="option => option.value" :clearable="false" :options="[{label: 'Mensal', value: 'monthly'}, {label: 'Quinzenal', value: 'biweekly'}, {label: 'Semanal', value: 'weekly'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Tipo de Pagamento</label>
          <v-select class="vue_select_drop_size_180" @input="filterAccounts" v-model="debit.type_payment_id" :reduce="option => option.value" :clearable="false"
            :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('debit.type_payment_id')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Conta</label>
          <v-select class="vue_select_drop_size_180" :disabled="!debit.type_payment_id" v-model="debit.account_id" :reduce="option => option.value" :clearable="false"
            :options="accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <!-- <span class="text-danger text-sm" v-show="errors.has('debit.account_id')">Este campo é obrigatório.</span> -->
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Plano de contas</label>
          <v-select class="vue_select_drop_size_180" v-model="debit.account_plan_id" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Nº de Parcelas</label>
          <!-- @input="recalculateTotal" -->
          <vs-input-number min="1" class="mt-2" v-model="debit.parcelsNumber"
            data-vv-as="Parcelas" data-vv-scope="debit" v-validate="'required|min_value:1'" name="parcelsNumber" />
          <span class="text-danger text-sm" v-show="errors.has('debit.parcelsNumber')">{{ errors.first('debit.parcelsNumber') }}</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input class="w-full mt-0" label="Valor 1ª Parcela" ref="firstParcelValue"
            :disabled="debit.parcelsNumber < 2"
            v-model="debit.firstParcelValue" v-currency="currencyConfig" />
          <span class="text-danger text-sm" v-if="errorfirstParcelValue">Este valor é maior que o total.</span>
        </div>

        <div class="vx-col md:w-1/5 w-full mt-0">        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">        </div>
        <div class="vx-col md:w-1/5 w-full mt-6">
          <div class="mt-0 flex flex-wrap items-center justify-end">
            <vs-button @click="generateParcels" :disabled="disableGenerateParcels" class="w-full mt-1" type="border" color="warning" icon-pack="feather" icon="icon-dollar-sign">Gerar Parcelas</vs-button>
          </div>
        </div>
      </div>

      <vs-divider position="left">
        <feather-icon icon="DollarSignIcon" class="mr-0" svgClasses="w-4 h-4" /><span class="mb-4">Parcelas</span>
      </vs-divider>

      <!-- PARCELAS -->
      <vs-table v-if="debit.transactions.length" :data="debit.transactions" hoverFlat style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th>#</vs-th>
          <vs-th>Valor</vs-th>
          <vs-th>Vencimento</vs-th>
          <vs-th>Forma Pagamento</vs-th>
          <vs-th>Conta</vs-th>
          <vs-th>Situação</vs-th>
          <vs-th>Pago Em</vs-th>
          <!-- <vs-th>Plano de Contas</vs-th> -->
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="i" v-for="(parcel, i) in data" style="opacity: 1 !important">
            <vs-td> {{ i + 1 }} </vs-td>
            <vs-td>
                <currency-input class="w-32 vs-inputx vs-input--input normal hasValue"
                  @input="parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0;"
                  v-model="parcel.value_parcel"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" v-model="parcel.expiration_date"
                data-vv-as="Vencimento" data-vv-scope="debit" v-validate.initial="{ required: true }" :name="'expiration_date'+i" />
              <span class="text-danger text-sm" v-show="errors.has('debit.expiration_date'+i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" @input="filterAccountsParcels(i)" v-model="parcel.type_payment_id" :reduce="option => option.value" :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" :disabled="!parcel.type_payment_id" v-model="parcel.account_id"
                :reduce="option => option.value" :options="parcel.accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                data-vv-as="Conta" data-vv-scope="debit" v-validate.initial="{ required: !parcel.account_id }" :name="'account_id'+i">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('debit.account_id'+i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <div class="flex flex-wrap items-center justify-center">
                <vs-switch class="w-28 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash"
                  @input="parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0;
                  parcel.situation ? parcel.payday = today : parcel.payday = ''
                  parcel.situation ? '' : parcel.futureDate = false;
                  checkPayday(parcel)" v-model="parcel.situation">
                  <span slot="off">ABERTA</span> <span slot="on">QUITADA</span>
                </vs-switch>
              </div>
            </vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" :disabled="!parcel.situation" v-model="parcel.payday" @change="checkPayday(parcel)"
              data-vv-as="Pago em" data-vv-scope="debit" v-validate="{ required: !!parcel.situation }" :name="'payday'+i" />
              <span class="text-danger text-sm" v-show="errors.has('debit.payday'+i)">Campo obrigatório</span>
              <span class="text-danger text-sm" v-show="parcel.futureDate">Data futura não permitida</span>
            </vs-td>
            <!-- <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" v-model="parcel.account_plan_id" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td> -->
          </vs-tr>

        </template>
      </vs-table>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save()" :disabled="!validateForm">Cadastrar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import _ from 'lodash'
import moment from 'moment'
import { getValue, CurrencyDirective, CurrencyInput } from 'vue-currency-input'
const permissionPassword = () => import(/* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue')

import moduleEmployee           from '@/store/employee/moduleEmployee.js'
import moduleServiceMaintenance from '@/store/service-maintenance/moduleServiceMaintenance.js'
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'

//AJUSTE DE VENCIMENTOS 30 DIAS EM MESES COM MENOS DE 31 DIAS
Date.isLeapYear = function (year) {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
}
Date.getDaysInMonth = function (year, month) {
  return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
}
Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear())
}
Date.prototype.getDaysInMonth = function () {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth())
}
Date.prototype.addMonths = function (value) {
  const n = this.getDate()
  this.setDate(1)
  this.setMonth(this.getMonth() + value)
  this.setDate(Math.min(n, this.getDaysInMonth()))
  // return `${this.getFullYear()}-0${this.getMonth() + 1}-${this.getDate()}`
  return this
}

export default {
  name: 'new-medical-modal',

  components: {
    vSelect,
    CurrencyInput,
    permissionPassword
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    vehicle: {
      Object, required: true
    }
  },

  computed: {
    serviceMaintenances () {
      return this.$store.getters['serviceMaintenance/forSelect']
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    validateForm () {
      return !this.errors.any()
    },
    firstValue () { //controlo no watch para não permitir valor maior que o total
      return this.debit.firstParcelValue
    },
    firstParcelValue () {
      return this.debit.transactions[0] && this.debit.transactions[0].value_parcel
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterDespesa']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    }
  },

  data () {
    return {
      localShow: false,
      providerOptions: [],
      vehicleMaintenance: {
        nota_fiscal: null,
        date_of_exchange: '',
        exchange_expiration: '',
        current_mileage: 0,
        next_exchange: 0,
        employee_id: null,
        provider_id: null,
        value_total: 0,
        services: [{ quantity: 1, value: 0, service: null }],
        service_description: null,
        vehicle: this.vehicle
      },

      disableGenerateParcels: false,
      accountsFilteredOptions: [],
      popupPermissionPassword: false,
      errorfirstParcelValue: false,
      currencyConfig:  {
        currency: {prefix: 'R$ '},
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      },

      debit: {
        account_id: null,
        type_payment_id: null,
        account_plan_id: null,
        parcelsNumber: 1,
        total: 0,
        firstParcelValue: 0,
        expirationDateFirstParcel: '',
        frequency: 'monthly',
        applyInterest: true,
        observation: null,

        transactions: []
      }
    }
  },
  directives: { currency: CurrencyDirective },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      this.$validator.pause()
      //COMO OS CAMPOS APARECEM E SOMEM DINAMICAMENTE, PRECISO DO BLOCO ABAIXO PARA EVITAR ERROS DE VALIDAÇÃO
      this.$nextTick(() => {
        this.$validator.resume()
        this.$validator.errors.clear()
        this.$validator.validateScopes()
      })

      if (!val) {
        this.vehicleMaintenance = {
          nota_fiscal: null,
          date_of_exchange: '',
          exchange_expiration: '',
          current_mileage: 0,
          next_exchange: 0,
          employee_id: null,
          provider_id: null,
          value_total: 0,
          services: [{ quantity: 1, value: 0, service: null }],
          service_description: null,
          vehicle: this.vehicle
        }

        this.debit = {
          account_id: null,
          type_payment_id: null,
          account_plan_id: null,
          parcelsNumber: 1,
          total: 0,
          firstParcelValue: 0,
          expirationDateFirstParcel: '',
          frequency: 'monthly',
          applyInterest: true,
          observation: null,

          transactions: []
        }
        this.$emit('cancel')
      } else {
        this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('accountPlan/fetchAll').then(() => { }).catch(err => { console.error(err) })

        this.vehicleMaintenance = JSON.parse(JSON.stringify(this.$store.getters['vehicleMaintenance/get']))
        this.providerOptions.splice(0)
        this.providerOptions.push({
          value: this.vehicleMaintenance.provider.id,
          label: `${this.vehicleMaintenance.provider.name} ${this.vehicleMaintenance.provider.cpf}`,
          account_plan_id: this.vehicleMaintenance.provider.account_plan_id
        })
        this.debit.expirationDateFirstParcel = this.vehicleMaintenance.transactions.length ? this.vehicleMaintenance.transactions[0].expiration_date : ''
        this.debit.parcelsNumber = this.vehicleMaintenance.transactions.length

        this.$store.dispatch('account/fetchAll').then(() => {
          this.debit.transactions = this.vehicleMaintenance.transactions
          this.debit.transactions.forEach((element, i) => {
            this.filterAccountsParcels(i)
          })
        }).catch(err => { console.error(err) })

      }
    },
    firstParcelValue (newValue) {
      this.recalculateParcelsValue(newValue)
    },
    firstValue () {
      const value = getValue(this.$refs.firstParcelValue)
      this.debit.transactions = []
      if (value > this.vehicleMaintenance.value_total) {
        this.errorfirstParcelValue = true
      } else {
        this.errorfirstParcelValue = false
      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    // SELECIONA O PLANO DE CONTAS CONFORME O PROVIDER
    selectedProvider () {
      const provider = this.providerOptions.find(e => e.value === this.vehicleMaintenance.provider_id)
      if (provider) this.debit.account_plan_id = provider.account_plan_id
    },

    //EVITA BAIXA COM DATA FUTURA
    checkPayday (parcel) {
      if (moment(parcel.payday).year() > 1000) {
        const difDays = moment(parcel.payday).diff(moment(this.today), 'days')
        if (difDays > 0) {
          parcel.futureDate = true
          this.disableSave = true
        } else {
          parcel.futureDate = false
          //checa se há alguma parcela com pagamento futuro
          const index = this.debit.transactions.findIndex(e => e.futureDate)
          if (index === -1) this.disableSave = false
        }
      } else {
        parcel.futureDate = false
        //checa se há alguma parcela com pagamento futuro
        const index = this.debit.transactions.findIndex(e => e.futureDate)
        if (index === -1) this.disableSave = false
      }
    },

    isSameOrAfterToday (e) {
      if (moment(e).isValid()) return moment(e).isSameOrAfter(moment().format('YYYY-MM-DD'))
      return true
    },
    addServiceField () {
      // this.resetParcels()
      this.vehicleMaintenance.services.push({
        quantity: 1,
        value: 0,
        service: null
      })
    },
    removeServiceField (index) {
      // this.resetParcels()
      this.vehicleMaintenance.services.splice(index, 1)
      // this.calculateTotal()
    },

    fetchproviderOptions (search, loading) {
      if (search.length) {
        loading(true)
        // busco alunos e fornecedores
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'PROVIDER' })
          .then(function (response) {
            this.providerOptions = []
            for (const k in response.data) {
              this.providerOptions.push({
                value: response.data[k].id,
                label: `${response.data[k].name} ${response.data[k].cpf || ''}`,
                account_plan_id: response.data[k].account_plan_id
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    async save (pass = false) {
      try {
        /**
         * CHECAR PAGAMENTO RETROATIVO
         */
        if (!pass) {
          const retroactiveParcels = this.debit.transactions.filter(e => {
            if (moment(e.payday).diff(moment(this.today), 'days') < 0) return true
          })

          if (retroactiveParcels.length) {
            this.popupPermissionPassword = true
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: 'Há parcelas com baixa retroativa',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
            return
          }
        }

        this.$vs.loading()
        await this.$store.dispatch('vehicleMaintenance/update', { maintenance: this.vehicleMaintenance, transaction: this.debit })
        this.$vs.loading.close()
        this.action()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // FILTRA AS CONTAS CONFORME O TIPO DE PAGAMENTO
    filterAccounts () {
      this.debit._id = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === this.debit.type_payment_id)
      })
      this.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) this.debit.account_id = myArrayFiltered[0].value
    },
    // FILTRA AS CONTAS NO FOR DAS PARCELAS CONFORME O TIPO DE PAGAMENTO
    filterAccountsParcels (i) {
      const parcel = this.debit.transactions[i]
      parcel.account = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === parcel.type_payment_id)
      })
      parcel.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) parcel.account_id = myArrayFiltered[0].value
    },
    generateParcels () {
      this.disableGenerateParcels = true
      setTimeout(() => { this.disableGenerateParcels = false }, 1000) //TRAVA O BOTÃO POR 3 SEGUNDOS
      this.debit.transactions = []

      const valueParcel = this.vehicleMaintenance.value_total / parseInt(this.debit.parcelsNumber)
      let expirationDate = new Date(`${this.debit.expirationDateFirstParcel}T00:00:00`)

      for (let parcel = 1; parcel <= this.debit.parcelsNumber; parcel++) {
        if (parcel > 1) {
          if (this.debit.frequency === 'monthly') {
            expirationDate = new Date(`${this.debit.expirationDateFirstParcel}T00:00:00`).addMonths(parcel - 1)
          } else if (this.debit.frequency === 'biweekly') expirationDate.setDate(expirationDate.getDate() + 15)
          else if (this.debit.frequency === 'weekly') expirationDate.setDate(expirationDate.getDate() + 7)
        }
        this.debit.transactions.push({
          futureDate: false,
          type: 'DEBIT',
          // eslint-disable-next-line
          expiration_date: `${expirationDate.getFullYear()}-${('0' + (expirationDate.getMonth()+1)).slice(-2)}-${('0' + (expirationDate.getDate())).slice(-2)}`,
          payday: !(this.accountsFilteredOptions.find(o => o.card_account)) ? '' : this.today,
          value_pay: !(this.accountsFilteredOptions.find(o => o.card_account)) ? 0 : valueParcel,
          parcel,
          value_parcel: valueParcel,
          situation: !!(this.accountsFilteredOptions.find(o => o.card_account)), // false = PENDING | true = PAID
          value_total: this.vehicleMaintenance.value_total,
          observation: this.debit.observation,
          account_plan_id: this.debit.account_plan_id,
          account_id: this.debit.account_id,
          type_payment_id: this.debit.type_payment_id,
          accountsFilteredOptions: this.accountsFilteredOptions
        })
      }

      //ESTE BLOCO SERVE APENAS PARA CASOS EM QUE A PRIMEIRA PARCELA É DEFINIDA ANTES DE GERAR AS PARCELAS
      //CASO NÃO HAJA UM VALOR INICIAL DE PRIMEIRA PARCELA COMENTAR O BLOCO É O SUFICIENTE
      const v = getValue(this.$refs.firstParcelValue)
      if (this.debit.parcelsNumber > 1 && v > 0) {
        this.debit.transactions[0].value_parcel = v
        this.recalculateParcelsValue(v)
      }
    },
    recalculateParcelsValue (firstParcelValue) {
      if (this.debit.transactions.length) { //evita erro
        if (firstParcelValue > this.vehicleMaintenance.value_total) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O valor digitado é maior que o valor total do contrato.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })

          this.debit.transactions[0].value_parcel = 0
          return
        }

        const valueParcels = (this.vehicleMaintenance.value_total - firstParcelValue) / (parseInt(this.debit.parcelsNumber) - 1)
        for (let parcel = 1; parcel < (this.debit.parcelsNumber); parcel++) {
          this.debit.transactions[parcel].value_parcel = valueParcels
          if (this.debit.transactions[parcel].situation) {
            this.debit.transactions[parcel].value_pay = this.debit.transactions[parcel].value_parcel
          } else {
            this.debit.transactions[parcel].value_pay = 0
          }
        }
      }
    }
  },
  created () {
    this.debouncedGetSearchProvider = _.debounce(this.fetchproviderOptions, 600)

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleServiceMaintenance.isRegistered) {
      this.$store.registerModule('serviceMaintenance', moduleServiceMaintenance)
      moduleServiceMaintenance.isRegistered = true
    }
    this.$store.dispatch('serviceMaintenance/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    if (!moduleAccount.isRegistered) {
      //  this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }

  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }

  .vue_select_drop_size_160 .vs__dropdown-menu {
    max-height: 160px;
  }
  .vs-tooltip {
    z-index: 99999;
  }
</style>
